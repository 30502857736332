<template>
  <div class="woolux">
    <section class="woolux-banner">
      <div class="container">
        <img src="~/static/woolux/logo.webp" alt="logo" loading="lazy" />

        <div class="links">
          <button @click="scrollToSection($refs.masteryRef)">Mastery</button>
          <button @click="scrollToSection($refs.innovationRef)">Innovation</button>
          <button @click="scrollToSection($refs.ethicsRef)">Ethics</button>
        </div>

        <div class="desktop-only">
          <p>Wool is a unique natural material that had been formed for thousands of years to protect the inhabitants of
            our planet from its harsh conditions. 100% natural, resilient, strong, and incredibly soft fibers form the
            basis of many excellent Togas products today.</p>
          <p class="text-wrap">For decades, we have been selecting and processing the best wool varieties from around
            the world and accumulating knowledge that today is recorded in the WOOLUX quality standard, developed at the
            Athens R&D center QC Worldwide by Togas Innovations.</p>
        </div>
      </div>
    </section>

    <section class="section-text mobile-only">
      <div class="section-container">
        <div class="dashed without-border-top"></div>
        <p>Wool is a unique natural material that had been formed for thousands of years to protect the inhabitants of
          our planet from its harsh conditions. 100% natural, resilient, strong, and incredibly soft fibers form the
          basis of many excellent Togas products today.</p>
        <p class="text-wrap">For decades, we have been selecting and processing the best wool varieties from around the
          world and accumulating knowledge that today is recorded in the WOOLUX quality standard, developed at the
          Athens R&D center QC Worldwide by Togas Innovations.</p>
      </div>
    </section>

    <section ref="masteryRef" class="woolux-mastery">
      <div class="section-container">
        <div class="dashed without-border-top"></div>
        <img class="logo" src="~/static/woolux/logo-mastery.webp" alt="logo" loading="lazy" />
        <span class="subtitle">Mastery</span>
        <p class="description">Only the best craftsmen can find and select the rarest high-quality wool fibers from
          around the world and then transform them into magnificent yarn,
          which is used to create our unsurpassed products.</p>
        <div class="dashed without-border-bottom"></div>
      </div>
    </section>

    <section class="section-resources line-height">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/woolux/mastery-banner.webp"
        />
        <img class="banner" src="~/static/woolux/mastery-banner-m.webp" alt="mastery" loading="lazy" />
      </picture>

      <div class="section-container">
        <div class="section-resource">
          <span class="subtitle">Raw material search</span>
          <p class="text">
            Scattered throughout the eastern part of the world, wool production farms greatly vary in the quality of
            their raw material. We are confident that only personal control can guarantee a constant level of the
            highest quality.
          </p>
          <p class="text">
            Therefore, Togas experienced scouts tirelessly travel to the furthest corners of the world to find and
            certify partners who we purchase high-class raw wool from.
          </p>
        </div>
        <div class="section-resource">
          <span class="subtitle">Fibre selection</span>
          <p class="text">Special fiber selection procedures conducted during the purchase of each batch of wool include
            numerous visual, tactile, strength, and molecular tests that are carried out on-site by our experts.
          </p>
          <p class="text">On-site testing requires the highest qualification of our experts and additional testing
            equipment costs, but we believe it is the only way to select the best, finest wool fibers worthy of the
            WOOLUX standard.</p>
        </div>
        <div class="section-resource">
          <span class="subtitle">Quality control</span>
          <p class="text">The entire volume of raw materials used in togas finest products, are found and carefully
            selected on the best farms. The selected materials then goes  to QC Worldwide laboratories located in Togas
            logistics hubs in China, UAE, Greece, and Turkey.
          </p>
          <p class="text">Additional extensive tests are carried out here to ensure that all 100% of the incoming fibers
            meet
            the established standards for size, color, purity, and composition, and thus are suitable for further
            processing.</p>
        </div>
      </div>
    </section>

    <section ref="innovationRef" class="woolux-innovation">
      <div class="section-container">
        <div class="dashed"></div>
        <img class="logo" src="~/static/woolux/logo-mastery.webp" alt="logo" loading="lazy" />
        <span class="subtitle">Innovation</span>
        <p class="description">Being a 100% natural material, wool itself has a wide range of genetically built-in
          protective and strength properties. Any interference in its natural structure can lead to disruption or
          destruction of these properties. The WOOLUX standard involves special procedures for cleaning raw materials,
          as well as further dyeing of wool if necessary.</p>
        <div class="dashed without-border-bottom"></div>
      </div>
    </section>

    <section class="section-resources line-height">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/woolux/innovation-banner.webp"
        />
        <img class="banner" src="~/static/woolux/innovation-banner-m.webp" alt="innovation" loading="lazy" />
      </picture>

      <div class="section-container">
        <div class="section-resource w-1">
          <span class="subtitle">Raw wool cleaning</span>
          <p class="text">
            To wash WOOLUX raw wool, mechanically filtered running water is used in combination with special grades of
            organic soap, which composition was developed by Togas Innovations with the assistance of European partners.
            Considering the method of washing and the necessary materials, all primary processing takes place only in
            our own or certified production facilities.
          </p>
        </div>
        <div class="section-resource w-3">
          <span class="subtitle">Dyeing of washed wool</span>
          <p class="text">It is known that the dyeing of wool significantly affects its properties. Therefore, we strive
            to leave as much WOOLUX standard wool undyed as possible. Today, all of the fibers used in the fillers of
            our sleep products are not dyed.
          </p>
          <p class="text">As for the fibers used later to produce our unique yarn for blankets and homewear, only
            organic dyes that meet European ecological standards are used in the dyeing process.</p>
        </div>
      </div>
    </section>

    <section ref="ethicsRef" class="woolux-ethics">
      <div class="section-container">
        <div class="dashed"></div>
        <img class="logo" src="~/static/woolux/logo-mastery.webp" alt="logo" loading="lazy" />
        <span class="subtitle">Ethics</span>
        <p class="description">The ethics of the modern world defines clear rules for conducting business, particularly
          related to the use of natural resources. At Togas, we clearly understand that only maximum transparency and
          control of production processes can be considered worthy of a leadership position.</p>
        <div class="dashed without-border-bottom"></div>
      </div>
    </section>

    <section class="section-resources line-height">
      <picture>
        <source
          media="screen and (min-width: 1024px)"
          srcset="~/static/woolux/ethics-banner.webp"
        />
        <img class="banner" src="~/static/woolux/ethics-banner-m.webp" alt="ethics" loading="lazy" />
      </picture>

      <div class="section-container">
        <div class="section-resource w-2">
          <span class="subtitle">Renewable raw materials</span>
          <p class="text">
            Wool is a 100% renewable material. For centuries, animals have shared their wool with us, and its collection
            is one of the most humane methods of obtaining any natural raw material.
          </p>
          <p class="text">No matter what type of wool is involved, we work with a limited number of farms and annually
            certify our partner farms for compliance with environmental and sustainability standards through Togas QC
            Worldwide.</p>
          <p class="text">In particular, we monitor the nutrition and water quality for animals, as well as the tools
            used for shearing, and the qualifications of wool collectors to minimize mechanical damage to animal
            skin.</p>
          <p class="text">We pay special attention to controlling the average lifespan of animals on certified farms. We
            strive to ensure that at least 90% of them reach the average lifespan of their particular species.</p>
        </div>
        <div class="section-resource w-2">
          <span class="subtitle">Recycling and reuse</span>
          <p class="text">Most of the unused wool, whether raw or finished, is eligible for recycling and reuse in Togas
            products such as covers and mattress comfort layers, as well as fillers for our pillows and comforters.</p>
          <p class="text">The fibers that failed to meet the WOOLUX standard during testing, mainly due to insufficient
            fiber length or color, are also used for these purposes.</p>
          <p class="text">Even in the case of wool fiber utilization, they naturally decompose in the soil over several
            years and slowly release valuable nutrients back into the ground.</p>
        </div>
      </div>
    </section>

    <section class="woolux-logo-togas">
      <div class="section-container">
        <div class="dashed without-border-bottom"></div>
        <img class="logo-togas" src="~/static/woolux/logo-togas.webp" alt="togas-logo">
      </div>
    </section>

    <section class="section-resources bg">
      <p class="title">Perfection of nature</p>
      <p class="description">Togas Wool</p>
      <div class="section-container">
        <SfLink
          :link="localePath('/woolux/cashmere')">
          <div class="section-resource">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/static/woolux/cashmire.webp"
              />
              <img src="~/static/woolux/cashmire-m.webp" alt="cashmire" loading="lazy" />
            </picture>
            <p class="country">China and India</p>
            <p class="animal">Tibetan <br class="desktop-only">cashmere</p>
          </div>
        </SfLink>

        <SfLink
          :link="localePath('/woolux/camel')">
          <div class="section-resource">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/static/woolux/camel.webp"
              />
              <img src="~/static/woolux/camel-m.webp" alt="camel" loading="lazy" />
            </picture>
            <p class="country">Mongolia</p>
            <p class="animal">Bactrian <br class="desktop-only">camel</p>
          </div>
        </SfLink>
        <SfLink :link="localePath('/woolux/merino')">
          <div class="section-resource">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/static/woolux/merino.webp"
              />
              <img src="~/static/woolux/merino-m.webp" alt="merino" loading="lazy" />
            </picture>
            <p class="country">Australia</p>
            <p class="animal">The Peppin <br class="desktop-only"> Merino</p>
          </div>
        </SfLink>

      </div>
    </section>

  </div>
</template>

<script>
import { SfLink } from '~/components';

export default {
  name: 'Woolux',
  components: {
    SfLink,
  },
  setup() {
    const scrollToSection = (section) => {
      const headerHeight = document.querySelector('.sf-header__sticky').offsetHeight;
      const scrollPosition = section.offsetTop - headerHeight;
      window.scrollTo({top: scrollPosition, behavior: 'smooth'});
    };

    return {
      scrollToSection
    };
  }
};
</script>

<style lang="scss" scoped>
section {
  background-size: cover;
  background-repeat: no-repeat;
}

.section-text {
  .section-container {
    .dashed {
      margin-bottom: 56px;
    }

    p {
      text-align: center;
      margin-bottom: 16px;

      &.text-wrap {
        margin-bottom: 56px;
      }
    }
  }
}

.section-container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @include for-desktop {
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  img {
    display: block;
    width: 100%;
  }

  .logo-togas {
    width: 80px;
    margin: 0 auto 32px;

    @include for-desktop {
      width: 114px;
      margin-bottom: 80px;
    }
  }

  p {
    margin: 0;
  }

  .logo {
    width: 154px;
    margin: 56px auto 24px;

    @include for-desktop {
      margin-top: 80px;
    }
  }

  .dashed {
    position: relative;
    left: -16px;
    width: 100vw;
    height: 58px;
    border-bottom: 1px dashed var(--togas-gold-color);
    border-top: 1px dashed var(--togas-gold-color);

    @include for-desktop {
      left: 0;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 58px;
      border-left: 1px dashed var(--togas-gold-color);
    }

    &.without-border-bottom {
      border-bottom: none;
    }

    &.without-border-top {
      border-top: none;
    }
  }

  .text-wrap-mob {
    margin-left: 35px;

    @include for-desktop() {
      margin-left: 0;
    }
  }

  .title {
    font: 300 28px / 28px var(--font-family--secondary);
    color: var(--togas-gold-color);
    margin-bottom: 16px;

    @include for-desktop {
      font-size: 58px;
      line-height: 51px;
      margin-bottom: 24px;
    }
  }

  .subtitle {
    font: 400 24px / 32px var(--font-family--secondary);
    display: block;
    color: var(--togas-grey-900-color);
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 32px;

    @include for-desktop {
      font-weight: 400;
      font-size: 36px;
      line-height: 36px;
    }
  }

  .description {
    font: 400 16px / 22px var(--font-family--primary);
    color: var(--togas-black-color);
    text-align: center;
    margin-bottom: 56px;

    @include for-desktop {
      max-width: 631px;
      margin-bottom: 80px;
    }
  }

  .text-wrap {
    max-width: 460px;
  }

  .m-0 {
    margin: 0;
  }
}

.woolux-mastery {
  .section-container {
    .dashed {
      &.without-border-top {
        border-top: 1px dashed var(--togas-gold-color);

        @include for-desktop {
          border-top: none;
        }
      }
    }
  }
}

.woolux-banner {
  background-image: url("~static/woolux/bg-mob.webp");
  height: 634px;

  @include for-desktop($mobile-max) {
    background-image: url("~static/woolux/bg.webp");
    height: auto;
  }

  .container {
    @include for-desktop {
      max-width: 1096px;
      margin: 0 auto;
    }
  }

  img {
    display: block;
    width: 300px;
    margin: 0 auto;
    padding: 185px 0 16px 0;

    @include for-desktop {
      width: 308px;
      padding: 120px 0 24px;
      margin: 0;
    }
  }

  .links {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 83px;
    margin: 0 auto;

    @include for-desktop {
      width: auto;
      flex-direction: row;
      justify-content: left;
      margin-left: -16px;
    }
  }

  button {
    font: 700 16px / 18px var(--font-family--primary);
    color: var(--togas-black-color);
    background: transparent;
    border: none;
    text-transform: uppercase;
    letter-spacing: .8px;
    padding: 0;
    margin-top: 16px;
    cursor: pointer;
    text-align: left;

    @include for-desktop {
      padding: 0 16px;
    }

    &:not(:last-child) {
      @include for-desktop {
        border-right: 2px solid var(--togas-black-color);
      }
    }
  }

  p {
    width: 552px;
    margin: 40px 0 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .text-wrap {
    margin: 0;
    padding-bottom: 200px;
  }
}

.section-resources {
  --link-text-decoration: none;

  &.bg {
    background-color: var(--togas-grey-50-color);

    .description {
      font-weight: 700;

      @include for-desktop {
        font-weight: 500;
      }
    }
  }

  &.line-height {
    .section-resource {
      .text {
        @include for-desktop {
          line-height: 24px;
        }
      }
    }
  }

  .title,
  .description {
    font: 400 24px / 30px var(--font-family--secondary);
    padding: 57px 0 16px;
    margin: 0;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
    color: var(--togas-gold-color);

    @include for-desktop {
      padding-top: 80px;
      font-size: 36px;
      line-height: 48px;
    }
  }

  .description {
    padding: 0;
    margin-bottom: 32px;
    text-transform: none;
    color: var(--togas-grey-900-color);

    @include for-desktop {
      margin-bottom: 40px;
    }
  }

  .country {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: .8px;
    text-transform: uppercase;
    color: var(--togas-gold-color);
  }

  .animal {
    font: 700 24px / 30px var(--font-family--secondary);
    text-align: center;
    font-style: normal;
    color: var(--togas-grey-900-color);

    @include for-desktop {
      font-size: 36px;
      font-weight: 500;
      line-height: 40px;
    }
  }

  .banner {
    width: calc(100% - 32px);
    margin-bottom: 32px;
    margin-left: 16px;

    @include for-desktop {
      display: block;
      width: auto;
      max-width: 1096px;
      margin: 0 auto 40px;
    }
  }

  .section-container {
    gap: 24px;
    align-items: flex-start;
    padding: 0 16px 40px;

    @include for-desktop {
      flex-direction: row;
      justify-content: space-between;
      gap: 88px;
      padding: 0 12px 60px;
    }
  }

  .section-resource {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include for-desktop {
      gap: 24px;
      max-width: 333px;
    }

    &.w-1 {
      @include for-desktop {
        max-width: 423px;
      }
    }

    &.w-3 {
      @include for-desktop {
        max-width: 603px;
      }
    }

    &.w-2 {
      @include for-desktop {
        max-width: 513px;
      }
    }

    .text {
      font-size: 16px;
      text-align: left;
    }
  }

  .subtitle {
    text-align: left;
    text-transform: none;
    font-weight: 700;
    margin: 0;
    color: var(--togas-gold-color);

    @include for-desktop {
      font-weight: 500;
    }
  }
}
</style>
